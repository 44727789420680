$(document).ready(function() {

	// ==========[ COOKIE CONSENT ]==========
	if (typeof $.cookie('cookie_pref_saved') === 'undefined') {
		setTimeout(function () {
			//UIkit.modal($("#cookie-modal")).show();
			$.fancybox.open({src: "#cookie-modal", type: "inline"});
		}, 5000);
	}

	$("#submit_cookie").click(function () {
		//UIkit.modal($("#cookie-modal")).hide();
		$.fancybox.close();

		let form = $("#cookie_form");
		let form_data = new FormData(form[0]);
		form_data.append("js_url", "js_set");
		$.ajax({
			url: '/ajax/',
			data: form_data,
			enctype: "multipart/form-data",
			dataType: "json",
			type: "post",
			processData: false,
			contentType: false,		
			success: function(data){
				if(data.html){
					$('head').append($(data.html));
				}
			}
		});
	});

	$("#approve_all").click(function(){
		$('input[type=checkbox]', '#cookie_form').attr('checked', 'checked');
		$('#submit_cookie').trigger('click');
	});

	$("#show_cookie_settings").click(function () {
		//UIkit.modal($("#cookie-modal")).show();
		$.fancybox.open({src: "#cookie-modal", type: "inline"});
	});


	/* =====  MOBILE MENU TOGGLE  ====== */
	$(".offCanvasToggler").click(function(event) {
		event.preventDefault();
		$(".off-canvas-nav").toggleClass("active");
		$(".fullscreen-overlay").toggleClass("active");
		$("html, body").toggleClass("no-scrolling");
	});

	/*======  INITS  ======*/

	/*----------  IMAGES TO SVG  ----------*/

	imageToSvg("svg");

	/*----------  YEAR RANGE SLIDER  ----------*/

	if ($("#year-range").length) {
		const yearRange = document.getElementById("year-range");

		var start = parseInt($("#year-range").data('start'));
		var stop = parseInt($("#year-range").data('stop'));
		var min = parseInt($("#year-range").attr('data-min'));
		var max = parseInt($("#year-range").attr('data-max'));
		var yearSliderTest = noUiSlider.create(yearRange, {
			range: {
				min: min,
				max: max
			},
			tooltips: true,
			format: wNumb({
				decimals: 0
			}),
			step: 1,
			start: [start, stop],
		});

		yearSliderTest.on('change', function () { buildUrl() });
	}



	/*----------  WORKING HEIGHT RANGE SLIDER  ----------*/

	if ($("#height-range").length) {
		const heightRange = document.getElementById("height-range");

		var start = parseInt($("#height-range").data('start'));
		var stop = parseInt($("#height-range").data('stop'));
		var min = parseInt($("#height-range").attr('data-min'));
		var max = parseInt($("#height-range").attr('data-max'));
		var heightSliderTest = noUiSlider.create(heightRange, {
			range: {
				min: min,
				max: max
			},
			tooltips: true,
			format: wNumb({
				decimals: 0
			}),
			step: 1,
			start: [start, stop],
		});

		heightSliderTest.on('change', function () { buildUrl() });
	}

	/*----------  TIMELINE INIT  ----------*/

	$(".timeline").timeline({ verticalTrigger: "0%" });

	/*======  SLIDERS  ======*/

	/*----------  FULL WIDTH SLIDER  ----------*/

	$(".full-width-slider .slides").slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		dots: false,
		prevArrow: $(".full-width-slider .slider-controls .prev-slide"),
		nextArrow: $(".full-width-slider .slider-controls .next-slide")
	});

	/*----------  PRODUCT SLIDER  ----------*/

	$(".product-slider .slides").slick({
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: true,
		dots: false,
		prevArrow: $(".product-slider .slider-controls .prev-slide"),
		nextArrow: $(".product-slider .slider-controls .next-slide"),
		autoplay: false,
		responsive: [
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				infinite: true
			}
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2
			}
		},
		{
			breakpoint: 576,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: true,
				autoplaySpeed: 3000
			}
		}
		]
	});

	/*----------  GALLERY SLIDER  ----------*/

	$(".gallery-slider .slides").slick({
		slidesToShow: 5,
		slidesToScroll: 1,
		arrows: true,
		dots: false,
		prevArrow: $(".gallery-slider .slider-controls .prev-slide"),
		nextArrow: $(".gallery-slider .slider-controls .next-slide"),
		autoplay: false,
		responsive: [
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 4,
				slidesToScroll: 4,
				infinite: true
			}
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2
			}
		},
		{
			breakpoint: 576,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: true,
				autoplaySpeed: 3000
			}
		}
		]
	});

	/*======  ACTIONS  ======*/

	/*----------  FILTERS MENU FOLD  ----------*/

	$(".filter-sidebar .filter-header").click(function(event) {
		event.preventDefault();
		const filter = $(this).data("filter");

		$(`.filter-sidebar .filter-content[data-filter="${filter}"]`).slideToggle();
		$(`.filter-sidebar .filter-content[data-filter="${filter}"]`).toggleClass(
			"active"
			);
		$(this).toggleClass("active");
	});

	/*----------  MOBILE FILTER TOGGLE  ----------*/

	$("#toggle-mobile-filters").click(function(event) {
		event.preventDefault();

		$(".filters-mobile").slideToggle();
	});

	/*----------  PRODUCT DETAIL GALLERY  ----------*/

	$(".detail-gallery .thumbnails .thumbnail").click(function(event) {
		event.preventDefault();

		const imageUrl = $(this).attr("href");

		$(".detail-gallery .thumbnails .thumbnail").removeClass("active");
		$(this).addClass("active");

		$(".detail-gallery .main-image").attr("href", imageUrl);
		$(".detail-gallery .main-image").css(
			"background-image",
			`url(${imageUrl})`
			);
	});

	$(".detail-gallery .main-image").click(function(event){
		event.preventDefault(); 
		var index = $(".detail-gallery .thumbnails .thumbnail.active").index();
		$.fancybox.open($('.detail-gallery .thumbnails .thumbnail'), {
			'loop': true
		}, index);
	});

	/*----------  PRODUCT FILTERING  ----------*/  

	var busy = false;

	function buildUrl(pn){
		if(!busy){
			busy = true;
			$('#loader').show();
			$('#product-block').hide();

			var q = $('#filter-search').val();        
			var year = '';
			var height = '';
			var filters = {};
			var type = '';
			var sort = '';

			if(pn == undefined || pn == '' || pn == 0){
				pn = 1;
			}

			type = $('.btn-view.active').attr('data-value');
			sort = $('#sort').val();

			$('.filter.active').each(function () {

				if(typeof(filters[$(this).attr("data-group")]) == 'undefined'){
					filters[$(this).attr("data-group")] = [];
				}    

				filters[$(this).attr("data-group")].push($(this).attr("data-id"));

			});

			if($('#year-range').length && (yearSliderTest.get()[0] !== $("#year-range").attr('data-min') || yearSliderTest.get()[1] !== $("#year-range").attr('data-max') ) ){
				year = yearSliderTest.get();

			}

			if($('#height-range').length && (heightSliderTest.get()[0] !== $("#height-range").attr('data-min') || heightSliderTest.get()[1] !== $("#height-range").attr('data-max') ) ){
				height = heightSliderTest.get();

			}

			$.ajax({
				data: {'q' : q, 'filters' : filters, 'year' : year, 'height' : height, 'type' : type, 'sort' : sort, 'pn' : pn},
				dataType: 'json',
				type: 'post',
				success: function(d){
					if(d.hide_header){
						$('#products-header').hide();
					}else{
						$('#products-header').fadeIn(400);
					}
					$('#product-block').html(d.html).fadeIn(400);
					$('#filters-block').html(d.html_filters);

					if(d.count > 0 || d.html_filters != ''){
						$('#counter-tag').html(d.count);
						$('#counter-text').css("display", "inline-block");
					}else{
						$('#counter-tag').html('');
						$('#counter-text').hide();
					}
					$('#pagination').html(d.pagination);
					busy = false;
					$('#loader').hide();
				}

			});
		}
	}

	// PAGINATION
	$('#pagination').on('click', 'a', function(e){
		if(!busy){
			e.preventDefault();
			buildUrl($(this).text());
		}
	});

    // SEARCH
    $('#filter-search').on('blur', function(e){
    	if(!busy){
    		buildUrl(1);
    	}
    }).on('keypress', function(e){
    	if(!busy && e.which == 13) {
    		e.preventDefault();
    		buildUrl(1);
    		return false;
    	}
    });

    // ACTIVE STATES
    $(document).on("click", ".filter-block .filter", function(e) {
    	if(!$(this).hasClass('ignore')){
	    	e.preventDefault();
	    	if(!busy){
	    		$(this).toggleClass('active');
	    		buildUrl(1);
	    	}
    	}
    });

    // CAT FILTER BLOCK (SAME ACTION AS CLICKING ON FILTER)
    $(document).on("click", ".cat-filter-block", function(e) {
    	if(!$(this).hasClass('ignore')){
	    	e.preventDefault();
	    	if(!busy){
	      		//$(this).toggleClass('active');
	      		var current_id = $(this).attr('data-id');
	      		$('.filter-block .filter[data-id='+current_id+']').addClass('active');
	      		buildUrl(1);
	    	}
    	}
    });

    // GRID / LIST
    $('#sort').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
    	if(!busy){
    		buildUrl($('#pagination li.active a').text());
    	}
    });
    
    // SORT BY
    $(document).on("click", ".btn-view", function(e) {
    	e.preventDefault();
    	if(!busy){
    		$('.btn-view').removeClass('active');
    		$(this).addClass('active');
    		buildUrl($('#pagination li.active a').text());
    	}
    });


    // REMOVE BUTTONS
    $(document).on("click", ".remove-regular", function(e) {
    	e.preventDefault();
    	if(!busy){
    		var remove_value = $(this).attr('data-id');
    		$('.filter-block .filter[data-id="'+remove_value+'"]').toggleClass('active');
    		buildUrl(1);
    	}
    });

    $(document).on("click", ".remove-years", function(e) {
    	e.preventDefault();
    	if(!busy){
    		yearSliderTest.set([parseInt($("#year-range").attr('data-min')), parseInt($("#year-range").attr('data-max'))]);
    		buildUrl(1);
    	}
    });

    $(document).on("click", ".remove-heights", function(e) {
    	e.preventDefault();
    	if(!busy){
    		heightSliderTest.set([parseInt($("#height-range").attr('data-min')), parseInt($("#height-range").attr('data-max'))]);
    		buildUrl(1);
    	}
    });

    $(document).on("click", ".remove-all", function(e) {
    	e.preventDefault();
		if(!busy){
	    	$('.filter-block .filter.active:not(.ignore)').toggleClass('active');
	    	if ($("#year-range").length) {
	    		yearSliderTest.set([parseInt($("#year-range").attr('data-min')), parseInt($("#year-range").attr('data-max'))]);
	    	}
	    	if ($("#height-range").length) {
	    		heightSliderTest.set([parseInt($("#height-range").attr('data-min')), parseInt($("#height-range").attr('data-max'))]);
	    	}
	    	buildUrl(1);
    	}
    });

});


/*----------  PRODUCT SEARCHING HEADER  ----------*/  
var request;
$(document).on("input", ".ajax-search", function(e) {
	e.preventDefault();
	var url = $(this).data('url');
	if($(this).val().length > 2){
		if(typeof request != "undefined"){
			request.abort();
		}
		var q = $(this).val();
		var branch = parseInt($(this).attr('data-branch'));
		request = $.ajax({
			data: {'searched' : q, 'branch' : branch},
			dataType: 'json',
			type: 'get',
			url: url,
			success: function(d){
				//results in list steken 
				$('#searched_data').show();
				$('#searched_data_mobile').show();
				$('#searched_data').html(d.searched_html);
				$('#searched_data_mobile').html(d.searched_html);
			}
		});
	}
});

$(document).on("keypress", ".ajax-search", function(e){
    var keycode = e.keyCode || e.which;
    if(keycode === 13){
		if($(this).val().length > 2){
			if(typeof request != "undefined"){
				request.abort();
			}
			var url = $(this).data('url');
			var q = $(this).val();
			var branch = parseInt($(this).attr('data-branch'));
			window.location = url + '?searched=' + q + '&branch=' + branch;
		}
    }    
});

// Hidden wanneer weggeklikt 

$( document ).on('click', function (e){
	if ( $( e.target ).closest( '.ajax-search' ).length === 0 ) {
		$('#searched_data').hide();
		$('#searched_data_mobile').hide();
	}
});

// en escape

$(document).on("keydown", ".ajax-search", function (e){
	var keycode = e.keyCode || e.which;
	if(keycode === 27){
		if(typeof request != "undefined"){
			request.abort();
		}
		$('#searched_data').hide();
		$('#searched_data_mobile').hide();
	}
});

// ScrollTo
$( document ).on( 'click', 'a.scrollTo', function ( e ) {
	e.preventDefault();
	var id = $(this).attr('href');
	$('html,body').animate({scrollTop: $(id).offset().top},'slow');
});